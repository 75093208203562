import styled, { css } from "styled-components"

export const Container = styled.div`
  margin-bottom: 100px;
`

export const Header = styled.h1`
  margin-bottom: 40px;
  text-align: center;
`

export const SubHeader = styled.h2`
  margin-bottom: 48px;
  text-align: center;
`

export const Content = styled.div`
  @media (max-width: 890px) {
    display: flex;
    flex-direction: column;
  }
`

export const ImageWrapper = styled.div`
  width: 100%;
`

export const TextContainer = styled.div`
  border: 1px solid #000;
  padding: 40px 48px;
`

export const Description = styled.div`
  margin-top: 24px;
  margin-bottom: 46px;
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 32px;
`

export const Section = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 50%);
  @media (max-width: 890px) {
    display: flex;
    flex-direction: column;
    ${(props) =>
      props.reverse &&
      css`
        flex-direction: column-reverse;
      `}
  }
`
