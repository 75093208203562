import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 25%);
  border-left: 1px #000 solid;
  margin-bottom: 100px;
  border-bottom: 1px #000 solid;
  @media (max-width: 890px) {
    border-bottom: 1px #000 solid;
    display: flex;
    flex-direction: column;
  }
`

export const Section = styled.div`
  padding: 23px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 25px;
  border-top: 1px #000 solid;
  border-right: 1px #000 solid;
  border-top: 1px #000 solid;
  @media (max-width: 890px) {
    border-bottom: 1px #fff solid;
  }
`

export const ImageWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`
