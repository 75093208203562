import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Section, ImageWrapper } from "./style"
import { useIntl } from "gatsby-plugin-react-intl";

export const HomeFeatures = () => {
  const intl = useIntl();
  return (
    <Container>
      <Section>
        <ImageWrapper>
          <StaticImage
            src="../../images/feature-icon-1.png"
            alt="logo"
            placeholder="blurred"
            quality={100}
          />
        </ImageWrapper>
        <h4>{intl.formatMessage({ id: "HomeFeatures1" })}</h4>
      </Section>
      <Section>
        <ImageWrapper>
          <StaticImage
            src="../../images/feature-icon-2.png"
            alt="logo"
            placeholder="blurred"
            quality={100}
          />
        </ImageWrapper>
        <h4>{intl.formatMessage({ id: "HomeFeatures2" })}</h4>
      </Section>
      <Section>
        <ImageWrapper>
          <StaticImage
            src="../../images/feature-icon-3.png"
            alt="logo"
            placeholder="blurred"
            quality={100}
          />
        </ImageWrapper>
        <h4>{intl.formatMessage({ id: "HomeFeatures3" })}</h4>
      </Section>
      <Section>
        <ImageWrapper>
          <StaticImage
            src="../../images/feature-icon-4.png"
            alt="logo"
            placeholder="blurred"
            quality={100}
          />
        </ImageWrapper>
        <h4>{intl.formatMessage({ id: "HomeFeatures4" })}</h4>
      </Section>
    </Container>
  )
}
