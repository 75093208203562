import React from "react"
import { Button } from "../Button"
import { InfoButton } from "../InfoButton"
import { Screen } from "../Screen"
import { useIntl } from "gatsby-plugin-react-intl"
import {
  Container,
  TextContainer,
  Description,
  TopRight,
  Player,
  ScreenContainer,
  BottomRight,
  BottomLeft,
  InfoButtonContainer,
} from "./style"
import { StaticImage } from "gatsby-plugin-image"

export const HomeTop = () => {
  const intl = useIntl()
  return (
    <Container>
      <TextContainer>
        <h1>{intl.formatMessage({ id: "HomeTopTitle" })}</h1>
        <Description>
          {intl.formatMessage({ id: "HomeTopDescription" })}
        </Description>
        <Button
          text={intl.formatMessage({ id: "ContactButton" })}
          to="#contact-us"
        />
      </TextContainer>
      <TopRight>
        <ScreenContainer>
          <Screen to="#astroflap" reverse />
        </ScreenContainer>
        <Player to="#astroflap" id="top-left">
          <StaticImage
            src="../../images/flying-player.png"
            alt="logo"
            placeholder="blurred"
            quality={100}
          />
        </Player>
        <Player to="#astroflap" id="top-right">
          <StaticImage
            src="../../images/jumping-player.png"
            alt="logo"
            placeholder="blurred"
            quality={100}
          />
        </Player>
        <ScreenContainer>
          <Screen to="#astroflap" />
          <InfoButtonContainer>
            <InfoButton to="#astroflap" text="AstroFlap" />
          </InfoButtonContainer>
        </ScreenContainer>
      </TopRight>
      <BottomLeft>
        <ScreenContainer>
          <Screen to="#smokefusion" big reverse />
          <InfoButtonContainer reverse>
            <InfoButton to="#smokefusion" reverse text="SmokeFusion" />
          </InfoButtonContainer>
        </ScreenContainer>
        <Player to="#smokefusion">
          <StaticImage
            src="../../images/jumping-players.png"
            alt="logo"
            placeholder="blurred"
            quality={100}
          />
        </Player>
      </BottomLeft>
      <BottomRight>
        <Player to="#foxrunner">
          <StaticImage
            src="../../images/geared-jumping-player.png"
            alt="logo"
            placeholder="blurred"
            quality={100}
          />
        </Player>
        <ScreenContainer>
          <Screen to="#foxrunner" big />
          <InfoButtonContainer>
            <InfoButton to="#foxrunner" text="FoxRunner" />
          </InfoButtonContainer>
        </ScreenContainer>
      </BottomRight>
    </Container>
  )
}
