import styled from "styled-components"

export const MessageContainer = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  background-color: #f8f8f880;
  border: 2px solid #fffffff0;
  border-radius: 40px;
  grid-area: form;
  margin: 40px;
  margin-top: 200px;
  @media (max-width: 890px) {
    margin-top: 40px;
  }
`

export const Message = styled.p`
  font-size: 1.3rem;
  line-height: 3rem;
  text-align: center;
`

export const SecondMessage = styled.p`
  font-size: 2.3rem;
  line-height: 4rem;
  font-weight: bold;
  color: ${(props) => (props.success ? "#E0A1F8" : "#B3001B")};
`
