import React from "react"
import { Button } from "../Button"
import { ExternalButton } from "../ExternalButton"
import { useIntl } from "gatsby-plugin-react-intl"
import {
  Container,
  Header,
  SubHeader,
  Content,
  ImageWrapper,
  TextContainer,
  Description,
  Buttons,
  Section,
} from "./style"
import { StaticImage } from "gatsby-plugin-image"

export const HomeOurGames = () => {
  const intl = useIntl()
  return (
    <Container>
      <Header>{intl.formatMessage({ id: "HomeOurGamesHeader" })}</Header>
      <SubHeader>
        {intl.formatMessage({ id: "HomeOurGamesSubHeader" })}
      </SubHeader>
      <Content>
        <Section id="astroflap">
          <ImageWrapper>
            <StaticImage
              src="../../images/astroflap-thumbnail.jpg"
              alt="logo"
              placeholder="blurred"
              quality={100}
              objectFit="cover"
              style={{ height: "100%" }}
            />
          </ImageWrapper>
          <TextContainer>
            <h3>{intl.formatMessage({ id: "HomeOurGamesAstroflapTitle" })}</h3>
            <Description>
              {intl.formatMessage({ id: "HomeOurGamesAstroflapDescription" })}
            </Description>
            <Buttons>
              <ExternalButton
                text="demo"
                href="https://oakgames.it/games/astroflap/"
              />
              <Button
                white={+true}
                text={intl.formatMessage({ id: "MoreButton" })}
                to="games/astroflap/"
              />
            </Buttons>
          </TextContainer>
        </Section>
        <Section id="smokefusion" reverse>
          <TextContainer>
            <h3>
              {intl.formatMessage({ id: "HomeOurGamesSmokefusionTitle" })}
            </h3>
            <Description>
              {intl.formatMessage({ id: "HomeOurGamesSmokefusionDescription" })}
            </Description>
            <Buttons>
              <ExternalButton
                text="demo"
                href="https://oakgames.it/games/smokefusion/"
              />
              <Button
                white={+true}
                text={intl.formatMessage({ id: "MoreButton" })}
                to="games/smokefusion/"
              />
            </Buttons>
          </TextContainer>
          <ImageWrapper>
            <StaticImage
              src="../../images/smokefusion-thumbnail.jpg"
              alt="logo"
              placeholder="blurred"
              quality={100}
              objectFit="cover"
              style={{ height: "100%" }}
            />
          </ImageWrapper>
        </Section>
        <Section id="foxrunner">
          <ImageWrapper>
            <StaticImage
              src="../../images/foxrunner-thumbnail.jpg"
              alt="logo"
              placeholder="blurred"
              quality={100}
              objectFit="cover"
              style={{ height: "100%" }}
            />
          </ImageWrapper>
          <TextContainer>
            <h3>{intl.formatMessage({ id: "HomeOurGamesFoxrunnerTitle" })}</h3>
            <Description>
              {intl.formatMessage({ id: "HomeOurGamesFoxrunnerDescription" })}
            </Description>
            <Buttons>
              <ExternalButton
                text="demo"
                href="https://oakgames.it/games/foxrunner/"
              />
              <Button
                white={+true}
                text={intl.formatMessage({ id: "MoreButton" })}
                to="games/foxrunner/"
              />
            </Buttons>
          </TextContainer>
        </Section>
      </Content>
    </Container>
  )
}
