import styled, { css } from "styled-components"
import { Link } from "gatsby"

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 40px;
  @media (max-width: 890px) {
    grid-template-rows: repeat(4, auto);
    grid-template-areas:
      "top top"
      "middle middle"
      "bottom bottom"
      "text text";
  }
`

export const TextContainer = styled.div`
  @media (max-width: 890px) {
    grid-area: text;
  }
`

export const Description = styled.div`
  margin: 20px 0 30px;
`

export const InfoButtonContainer = styled.div`
  position: absolute;
  top: -10px;
  ${(props) =>
    props.reverse
      ? css`
          right: -42px;
        `
      : css`
          left: -36px;
        `}
`

export const BottomLeft = styled.div`
  display: flex;
  gap: 60px;
  @media (max-width: 890px) {
    grid-area: bottom;
  }
`

export const BottomRight = styled.div`
  display: flex;
  gap: 50px;
  justify-content: flex-end;
  @media (max-width: 890px) {
    grid-area: middle;
  }
`

export const TopRight = styled.div`
  display: flex;
  @media (max-width: 890px) {
    grid-area: top;
    margin: 50px 0;
  }
`

export const Player = styled(Link)`
  justify-self: center;
  align-self: center;
  display: inline-block;
  padding: 12px;
  border: 2px solid #ffffff00;
  border-radius: 8px;
  &:hover {
    border: 2px solid #ff3f41;
  }
  &:active {
    background: rgba(255, 63, 65, 0.2);
  }
  &#top-left {
    position: relative;
    left: -55px;
    bottom: -64px;
  }
  &#top-right {
    position: relative;
    left: 55px;
    bottom: -52px;
    z-index: 9;
    @media (max-width: 890px) {
      bottom: -45px;
      left: 25px;
      grid-area: top;
    }
  }
`
export const ScreenContainer = styled.div`
  position: relative;
`
