import React from "react"
import { MessageContainer, Message, SecondMessage } from "./style"
import { useIntl } from "gatsby-plugin-react-intl";

const ContactMessage = ({ success }) => {
  const intl = useIntl();
  if (success) {
    return (
      <MessageContainer>
        <Message>{intl.formatMessage({ id: "ContactMessageSuccess" })}</Message>
        <SecondMessage success={success}>{intl.formatMessage({ id: "ContactMessageThanks" })}</SecondMessage>
      </MessageContainer>
    )
  } else {
    return (
      <MessageContainer>
        <Message>{intl.formatMessage({ id: "ContactMessageError" })}</Message>
        <SecondMessage success={success}>{intl.formatMessage({ id: "ContactMessageSorry" })}</SecondMessage>
      </MessageContainer>
    )
  }
}

export default ContactMessage
