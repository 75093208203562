import * as React from "react"
import { Layout } from "../components/Layout"
import { HomeFeatures } from "../components/HomeFeatures"
import { HomeOurGames } from "../components/HomeOurGames"
import { HomeContactUs } from "../components/HomeContactUs"
import { HomeTop } from "../components/HomeTop"
import { Helmet } from "react-helmet"

const IndexPage = () => {
  return (
    <Layout>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <meta charSet="utf-8" />
        <title>OakPong</title>
        <meta
          name="description"
          content="OakPong - new ideas for your company"
        />
      </Helmet>
      <HomeTop />
      <HomeFeatures />
      <HomeOurGames />
      <HomeContactUs />
    </Layout>
  )
}

export default IndexPage
