import React from "react"
import ReCAPTCHA from "react-google-recaptcha"

const ReCaptcha = ({ onChange }) => {
  return (
    <ReCAPTCHA
      sitekey="6Le6rgghAAAAALYud0gt3YTIM1PjAlgVYJsqcOWP"
      onChange={() => onChange(true)}
      onExpired={() => onChange(false)}
    />
  )
}

export default ReCaptcha
