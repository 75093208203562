import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Container, ImageWrapper, Background, Info } from "./style"

export const InfoButton = ({ reverse, text, to }) => {
  return (
    <Container to={to}>
      <Background reverse={reverse} />
      <ImageWrapper>
        <StaticImage
          src="../../images/info-icon.png"
          alt="logo"
          height={22}
          placeholder="blurred"
          quality={100}
        />
      </ImageWrapper>
      <Info reverse={reverse}>
        <p>{text}</p>
      </Info>
    </Container>
  )
}
