import React from "react"
import {
  Container,
  Content,
  NormalScreen,
  HoverScreen,
  ActiveScreen,
} from "./style"
import { StaticImage } from "gatsby-plugin-image"

export const Screen = ({ to, big, reverse }) => {
  return (
    <Container reverse={reverse}>
      {!big ? (
        <Content to={to}>
          <NormalScreen>
            <StaticImage
              src="../../images/screen.png"
              alt="logo"
              placeholder="blurred"
              quality={100}
            />
          </NormalScreen>
          <HoverScreen>
            <StaticImage
              src="../../images/screen-hover.png"
              alt="logo"
              placeholder="blurred"
              quality={100}
            />
          </HoverScreen>
          <ActiveScreen>
            <StaticImage
              src="../../images/screen-active.png"
              alt="logo"
              placeholder="blurred"
              quality={100}
            />
          </ActiveScreen>
        </Content>
      ) : (
        <Content to={to}>
          <NormalScreen>
            <StaticImage
              src="../../images/screen.png"
              alt="logo"
              width={250}
              placeholder="blurred"
              quality={100}
            />
          </NormalScreen>
          <HoverScreen>
            <StaticImage
              src="../../images/screen-hover.png"
              alt="logo"
              width={250}
              placeholder="blurred"
              quality={100}
            />
          </HoverScreen>
          <ActiveScreen>
            <StaticImage
              src="../../images/screen-active.png"
              alt="logo"
              width={250}
              placeholder="blurred"
              quality={100}
            />
          </ActiveScreen>
        </Content>
      )}
    </Container>
  )
}
