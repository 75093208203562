import styled from "styled-components"

export const Content = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: 52% 45%;
  gap: 3%;
  @media (max-width: 890px) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`

export const Container = styled.div`
  margin: 100px auto 0;
  width: 100%;
`

export const Input = styled.input`
  font-size: 16px;
  width: 100%;
  border: none;
  border-bottom: 1px solid black;
  padding: 1em 0px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #212529;
    opacity: 0.5;
  }
`

export const TextArea = styled.textarea`
  font-size: 16px;
  width: 100%;
  border: none;
  min-height: 40px;
  border-bottom: 1px solid black;
  padding: 10px 1px;
  resize: vertical;
  font-family: Sans-serif;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #212529;
    opacity: 0.5;
  }
`

export const Label = styled.label`
  font-size: 12px;
  display: block;
`

export const Error = styled.p`
  font-size: 12px;
  margin-bottom: 10px;
  color: #d20808;
  font-size: 0.8em;
  margin-top: 0.7em;
`

export const Left = styled.div``

export const Right = styled.div``

export const Form = styled.form`
  margin-top: 40px;
  display: ${(props) => (props.isActive ? "flex" : "none")};
  flex-direction: column;
  border: 1px solid black;
  padding: 48px;
  @media (max-width: 890px) {
    padding: 20px;
    width: 100%;
    margin-bottom: 40px;
  }
`

export const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 40px;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  @media (max-width: 890px) {
    justify-content: center;
  }
`

export const ButtonWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 108px;
  height: 48px;
  border-radius: 10px;
  background-color: #252525;
  &:hover {
    background-color: #4a4a4a;
  }
  &:active {
    background-color: #7d7d7d;
  }
`

export const ButtonText = styled.h5`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
`
