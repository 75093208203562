import styled, { css } from "styled-components"
import { Link } from "gatsby"

export const Background = styled.div`
  position: absolute;
  top: 0px;
  border-radius: 4px;
  transform: ${(props) =>
    props.reverse ? "skew(0, -15deg)" : "skew(0, 15deg)"};
  background: #f36b6d;
  width: 28px;
  height: 30px;
`

export const Info = styled.div`
  border: 1px solid black;
  padding: 4px;
  background-color: #fff;
  display: none;
  position: absolute;
  ${(props) =>
    props.reverse
      ? css`
          left: 33px;
        `
      : css`
          right: 41px;
        `};
  top: -4px;
`

export const Container = styled(Link)`
  width: 36px;
  height: 36px;
  position: relative;
  display: block;
  &:hover {
    ${Background} {
      background-color: #ff3f42;
    }
    ${Info} {
      display: block;
    }
  }
  &:active {
    ${Background} {
      background-color: #ca263f;
    }
  }
`

export const ImageWrapper = styled.div`
  position: absolute;
  top: 4px;
  left: 3px;
`
