import styled from "styled-components"

export const Loader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
  @media (max-width: 890px) {
    margin-top: 0px;
    width: 100%;
  }
`
