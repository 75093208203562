import styled, { css } from "styled-components"
import { Link } from "gatsby"

export const NormalScreen = styled.div``

export const HoverScreen = styled.div`
  display: none;
`

export const ActiveScreen = styled.div`
  display: none;
`

export const Content = styled(Link)``

export const Container = styled.div`
  padding: 6px;
  ${(props) =>
    props.reverse &&
    css`
      transform: rotateY(180deg);
    `}
  &:hover {
    ${HoverScreen} {
      display: block;
    }
    ${NormalScreen} {
      display: none;
    }
    ${ActiveScreen} {
      display: none;
    }
  }
  &:active {
    ${ActiveScreen} {
      display: block;
    }
    ${HoverScreen} {
      display: none;
    }
    ${NormalScreen} {
      display: none;
    }
  }
`
